import Card from "components/Cards/Card";
import Layout from "components/layout";
import { useUI, TSearchbar } from "contexts/UI/UIManager";
import React, { FormEvent, useEffect, useState } from "react";
import { DashboardCards } from "components/Cards";
import s from "../components/layout/content/Content.module.css";
import cn from "classnames";

const Home = () => {
  // const { setSearchbar } = useUI();
  // useEffect(() => {
  //   (setSearchbar as (value: TSearchbar) => {})({
  //     inputReference: "",
  //     title: "Dashboard",
  //   });
  // }, []);
  return (
    <div className={cn(s.content)}>
      <DashboardCards.MenuItem link="/clients" text="Clients section" />
    </div>
  );
};

Home.Layout = Layout;
export default Home;
