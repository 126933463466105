import s from './Style.module.css'
import cn from 'classnames'
import { FC, useEffect, useRef } from 'react'
import Card from "components/Cards/Card";
import { IMenuItemCard } from 'types/UI/cards';
import Link from 'next/link';

const MenuItem = (item: IMenuItemCard) => {

    return (
        <Link href={item.link} passHref>
            <Card className={cn(s.root)} cols={2} rows={1}>
                <span>{item.text}</span>
            </Card>
        </Link>
    )
}

export default MenuItem;